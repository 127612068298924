import React, { useState } from "react";

import { useParams } from "react-router-dom";

import {
  DetailCard,
  Footer,
  FilterModal,
  Navbar,
  PreviewModal,
  SearchBar,
  Loader,
} from "../../Components";
import { useGetFilterAdvertiseQuery } from "../../Services/AdvertiseService";
import { useApp } from "../../Context";

export default function DetailPage() {
  const { city } = useParams();
  const { filters } = useApp();

  //services
  const { data: advertises, isLoading } = useGetFilterAdvertiseQuery({
    city: city ? city : filters.city,
    minArea: filters.minArea,
    rooms: filters.rooms,
    minPrice: filters.minPrice,
    maxPrice: filters.maxPrice,
    advertise_type: filters.advertise_type,
    category: filters.category,
  });

  const [showFilter, setShowFilter] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedAdData, setSelectedAdData] = useState(null);

  const handleShowFilter = () => setShowFilter((prevState) => !prevState);
  // const handleShowPreview = () => setShowPreview((prevState) => !prevState);

  const handleShowPreview = (adData) => {
    setSelectedAdData(adData);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setSelectedAdData(null);
    setShowPreview(false);
  };

  return (
    <>
      <Navbar />
      {isLoading && <Loader isLoading={isLoading} text="Loading..." />}

      <div className="bg-lightGreen mt-[88px] p-4  flex items-center justify-center">
        <div>
          <SearchBar handleShowFilter={handleShowFilter} />
        </div>
      </div>
      <p className="text-[12px font-helvetica lg:m-12 sm:m-5">
        Dein Gesuch wurde registriert. Sieh dir in der Zwischenzeit unsere Inserate an!
      </p>
      <p className="text-[20px] font-helvetica font-bold lg:m-12 sm:m-5">
        {advertises?.length} Ergebnisse
      </p>
      
      <div className="lg:px-12 sm:px-5  flex flex-col gap-4 mb-4">
        {advertises?.map((item, idx) => (
          <DetailCard
            key={idx}
            data={item}
            onClick={() => handleShowPreview(item)}
          />
        ))}
      </div>

      <Footer />
      <FilterModal open={showFilter} handler={handleShowFilter} />
      {selectedAdData !== null && (
        <PreviewModal
          open={showPreview}
          handleClose={handleClosePreview}
          data={selectedAdData}
        />
      )}
    </>
  );
}
