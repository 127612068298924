import React, { useEffect, useState } from "react";

import { useApp } from "../../Context";
import { useNavigate } from "react-router-dom";

//import components
import {
  AdvertiseModal,
  Feedback,
  Footer,
  FilterModal,
  HomeCard,
  Navbar,
  Button,
  SearchBar,
  Loader,
} from "../../Components";

//assets
import HeroImage from "../../Assets/background-img2.png";
import BackgroundImage from "../../Assets/background-img.png";
import { useGetCitiesQuery } from "../../Services/AdvertiseService";

export default function HomePage() {
  const navigate = useNavigate();

  const { showAdModal, handleShowAdModal } = useApp();

  //services

  const { data: cities, isLoading: loadingCities } = useGetCitiesQuery();

  const [showFilter, setShowFilter] = useState(false);
  const [next, setNext] = useState(6);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShowFilter = () => setShowFilter((prevState) => !prevState);

  const showMoreCities = () => {
    setNext((prevState) => prevState + 6);
  };

  return (
    <>
      <Navbar />
      {loadingCities && (
        <Loader isLoading={loadingCities} text="Please wait..." />
      )}
      {/* hero section */}
      <div className="mt-20 relative overflow-x-hidden">
        <img
          className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
          src={HeroImage}
          alt="hero-img"
        />
        <div className="!absolute inset-0 flex flex-col justify-center items-center text-center gap-10 md:px-20 px-5">
          <p className="font-georgia md:text-[45px] text-2xl text-white leading-snug">
            Auf der Suche nach <br /> einem neuen Zuhause?
          </p>
          <p className="text-white font-helvetica md:text-[18px] text-md">
            Registriere dich kostenlos für deine Immobiliensuche!
          </p>

          <div>
            <SearchBar handleShowFilter={handleShowFilter} showType />
          </div>

          <p className="text-white font-helvetica md:text-[12px] text-[8px]">
            Ihre Immobiliensuchprofil wird mit jedem Immobilienvermittler im
            Umkreis von 25 Km geteilt. So erhöhen sich Ihre Chance, dass
            passende Immobilienangebot zu erhalten.
          </p>
        </div>
      </div>
      <div className="mt-[87px] text-center flex flex-col gap-2">
        <p className="md:text-[35px] text-2xl font-georgia text-primary">
          Unsere Immobilienanzeigen
        </p>
        <p className="text-primary font-helvetica text-lg">
          Ausgewählte Inserate
        </p>
      </div>
      <div className="flex flex-col items-center justify-center mt-[51px]">
        <p>Städte</p>
        <div className="bg-primary h-1 w-24" />
      </div>
      {/* advertisement section */}
      <div className="mt-[51px] flex justify-center px-5">
        <div className="grid grid-rows-1 md:grid-cols-3 gap-5">
          {cities?.slice(0, next).map((item, idx) => (
            <HomeCard
              key={idx}
              data={item}
              onClick={() => navigate(`/detail/${item.city}`)}
            />
          ))}
        </div>
      </div>
      {cities?.length === 6 && (
        <div className="flex justify-center mt-[41px]">
          <Button
            title="Alle Inserate erkunden"
            variant="primary"
            onClick={showMoreCities}
          />
        </div>
      )}
      <p className="md:text-[35px] text-2xl font-georgia text-primary text-center mt-[61px]">
        Das sagen unsere Kunden
      </p>
      {/* client feedback section */}
      <div className="mt-[20px]">
        <Feedback />
      </div>

      <div className="mt-[50px] relative">
        <img
          className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
          src={BackgroundImage}
          alt="hero-img"
        />
        <div className="absolute top-[30%] left-20 md:w-1/4 w-3/4">
          <p className="md:text-[35px] text-2xl font-georgia text-white ">
            Privat inserieren
          </p>
          <p className="text-[18px] font-helvetica text-white font-light mt-[45px] mb-[20px]">
            Sie möchten Ihre Immobilie Privat vermitteln? Wir vernetzen Sie mit
            bereits registrierten Immobilieninteressenten.
          </p>
          <Button
            title="Erstellen"
            variant="secondary"
            onClick={handleShowAdModal}
          />
        </div>
      </div>
      <Footer />
      <FilterModal open={showFilter} handler={handleShowFilter} />
      <AdvertiseModal open={showAdModal} handler={handleShowAdModal} />
    </>
  );
}
